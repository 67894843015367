<template>
  <div class="clouds-stats">
    <base-datepicker-filter
      :periods="periods"
      :period="period"
      :value="dateFilter"
      class="clouds-stats__filter"
      @init="onFilterInit"
      @change="onFilterChange"
    />
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <div v-else class="clouds-stats__content">
        <div class="clouds-stats__grid l-flex-1">
          <div v-for="(item, key) in datasets" :key="key" class="l-col">
            <clouds-stats-item
              :title="$t(`titles.${key}`)"
              :labels="labels"
              :datasets="item"
              :deprecated="nodata"
              class="clouds-stats__item"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseDatepickerFilter from '@/components/BaseDatepicker/BaseDatepickerFilter';
import CloudsStatsItem from '../components/CloudsStatsItem.vue';
import { CloudTariff } from '@/models/BillMgr/CloudTariff';
import { format } from 'date-fns';
export default {
  name: 'CloudsStats',
  components: {
    BaseDatepickerFilter,
    CloudsStatsItem,
  },
  props: {
    tariff: {
      type: CloudTariff,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dateFilter: { start: new Date(), end: new Date() },
      periods: ['week', 'month', 'quarter', 'year'],
      period: 'week',
      dataRaw: [],
      nodata: false,
    };
  },
  computed: {
    tools() {
      return this.$store.state.moduleClouds.tools;
    },
    datasets() {
      return this.dataRaw.reduce((acc, item) => {
        if (!acc.unit) acc.unit = [{ label: this.$t('labels.unit'), data: [] }];
        acc.unit[0].data.push(parseInt(item.Unit));
        return acc;
      }, {});
    },
    labels() {
      return this.dataRaw.reduce((acc, item) => {
        acc.push(new Date(item.statdate));
        return acc;
      }, []);
    },
  },
  methods: {
    init() {
      this.isLoading = true;
      this.fetchStats().finally(() => (this.isLoading = false));
    },
    onFilterInit(value) {
      this.dateFilter = value;
      this.init();
    },
    onFilterChange(value) {
      this.dateFilter = value;
      this.fetchStats();
    },
    fetchStats() {
      const params = {
        func: this.tools.stat.func + '.simple',
        elid: this.tariff.id,
        period: 'other',
        periodstart: format(this.dateFilter.start, 'yyyy-MM-dd'),
        periodend: format(this.dateFilter.end, 'yyyy-MM-dd'),
      };
      return this.$store.dispatch('moduleClouds/fetchBillMgrToolAction', params).then(data => {
        if (data && data.model && data.model.reportdata && data.model.reportdata.itemstat) {
          this.nodata = false;
          this.dataRaw = data.model.reportdata.itemstat;
        } else {
          this.nodata = true;
        }
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "titles": {
      "unit": "Использование юнитов"
    },
    "labels": {
      "unit": "Unit, шт."
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.clouds-stats {
  &__filter {
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
