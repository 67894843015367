<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <page-block v-if="isStatEnabled" :title="$t('title.stats')" class="vps-detail-view__block">
        <clouds-stats :tariff="tariff" />
      </page-block>
    </transition>
  </div>
</template>

<script>
import PageBlock from '../components/PageBlock';
import CloudsStats from '../components/CloudsStats.vue';
import { CloudTariff } from '@/models/BillMgr/CloudTariff';
export default {
  name: 'CloudsView',
  components: {
    PageBlock,
    CloudsStats,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof CloudTariff,
    },
  },
  data() {
    return {};
  },
  computed: {
    tools() {
      return this.$store.state.moduleClouds.tools;
    },
    isStatEnabled() {
      return this.tools.stat.isEnable(this.tariff);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "plan": "Тарифный план",
      "stats": "Статистика"
    },
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
